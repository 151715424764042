

.shopping_cart_ico {
    position: absolute;
    @media screen and (max-width: 1199px) {
        top: 12px;
    }
    @media screen and (min-width: 1200px) {
        top: 30px;
    }
    right: 20px;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.cart_items_counter {
    background-image: linear-gradient(-90deg, #4DAAEA 0%, #2671D1 100%);
    position: absolute;
    @media screen and (max-width: 1199px) {
        top: 11px;
    }
    @media screen and (min-width: 1200px) {
        top: 25px;
    }
    right: 10px;
    width: 15px;
        height: 15px;
    border-radius: 10px;
    @include dflex(row, space-around, center);
    font-family: Metropolis-Bold;
    font-size: 11px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 25px;
}

.cart_main_page {
    @media screen and (max-width: 1200px) {
        display:none;
    }
}

.cart_header {
    @media screen and (min-width: 1200px) {
        display:none;
    }
}


@media (min-width: 1200px) {
    .cart {
        width: 1000px !important;
        margin: auto !important;
    }
}

.cart {
    & > .container {
        @include dflex(column, flex-start, flex-start);
    }

    h1 {
        color: $white;
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0;
        margin-bottom: 45px;
    }

    .item_cart {
        @include dflex(row, flex-start, flex-start);
        // // Small devices (landscape phones, 576px and up)
        // @media (max-width: 767px) {
        //     @include dflex(column, flex-start, flex-start);
        // }

        // // // Medium devices (tablets, 768px and up)
        // @media (min-width: 768px) {
        //     @include dflex(row, flex-start, flex-start);
        // }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }
        width: 100%;
        // height: 130px;
        border-radius: 20px;
        background: $sidebar-bg;
        margin-bottom: 20px;
        color: $white;

        & > .item_background {
            background-image: url(../../assets/images/item/item_bg.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            height: 130px;
            width: 130px;
            padding: 0px;

            -webkit-border-radius: 20px 0 0 20px;
            -moz-border-radius: 20px 0 0 20px;
            border-radius: 20px 0 0 20px;
            border: none;

            & > .item_image {
                height: 130px;
                width: 130px;

                -webkit-border-radius: 20px 20px 0 0;;
                -moz-border-radius: 20px 20px 0 0;;
                border-radius: 20px 20px 0 0;;
                border: none;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }
        }


        .item_content {
            @media screen and (max-width: 767px) {
                @include dflex(column, flex-start, flex-start);
            }
            @media screen and (min-width: 768px) {
                @include dflex(row, space-between, flex-start);
                width:100%;
            }
        }


        .item_desc {
            @include dflex(column, flex-start, flex-start);

            // min-width: 60%;
            @media (max-width: 576px) {
                margin: 5px 0 0 5px;
                min-width: 130px;
            }
            @media (min-width: 577px) and (max-width: 991px) {
                margin: 10px 0 0 10px;
                min-width: 230px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
                margin: 30px 0 0 30px;
                min-width: 230px;
            }
            @media (min-width: 1200px) {
                margin: 30px 0 0 30px;
                min-width: 50%;
            }

            h2 {
                font-family: Metropolis-Bold, sans-serif;
                font-weight: 700;
                font-size: 19px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 5px;
            }
            h4 {
                /* Author: johnycage: */
                font-family: Metropolis-Regular, sans-serif;
                font-weight: 500;
                font-size: 13px;
                color: rgba(255,255,255,0.30);
                letter-spacing: 0;
                // line-height: 38px;
                line-height: 18px; // TODO: look if not too samall
                margin: 0;
                span {
                    color: $white;
                    opacity: 0.3;
                }
                a {
                    color: $author-color;
                }
            }
        }

        .item_price {
            @media screen and (max-width: 767px) {
                margin: 5px;
            }
            @media screen and (min-width: 768px) and (max-width: 991px) {
                // margin: 50px 15px;
                margin: 50px 30px !important;
            }
            @media screen and (min-width: 992px) {
                margin: 50px 100px !important;
                // margin: 50px 15px;
            }
        }

        .item_action {
            width: 100%;
            @include dflex(row, flex-end, flex-end);
            margin: 45px 35px 0 0;

            @media screen and (max-width: 576px) {
                position: relative;
                left: -346px;
                top: -42px;

                button {
                    background: unset;
                }
            }
        }
    }
    .cart_action {
        width: 100%;
        @include dflex(row, space-between, flex-end);
    }
    .cart_summary {
        width: 100%;
        @include dflex(row, flex-end, flex-end);
    }

    .item_price {
        // @include dflex(row, flex-start, flex-start);
        width: 200px;

        font-family: Metropolis-Regular, sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 23px;
        // margin: 30px 15px;
        @media screen and (max-width: 767px) {
            margin: 5px;
        }
        @media screen and (min-width: 768px) {
            margin: 30px 15px;
        }
        @include dflex(row, flex-start, baseline);
        span {
            color: $wax-color;
        }
        i {
            font-style: normal;
            font-family: Metropolis-Regular;
            font-size: 13px;
            color: rgba(255,255,255,0.30);
            letter-spacing: 0;
            line-height: 38px;
        }
    }
}


// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }
