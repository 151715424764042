
@media (max-width: 767px) {
    .item_header {
        min-width: 290px;
        @include dflex-override(column, flex-start, flex-start);
        .item_background {
            // width: calc(100% + 1px) !important;
            // height: calc(100% + 1px) !important;
            // width: 345px !important;
            // height: 345px !important;
            height: 100% !important;
            width: 100% !important;
            background-size: 100% 100% !important;
            @include border-radius-override(20px 20px 0 0);
            .item_image {
                // width: calc(100% + 1px) !important;
                // height: calc(100% + 1px) !important;
                // width: 345px !important;
                width: 100% !important;
                height: 345px !important;
                @include border-radius-override(20px 20px 0 0);
            }
        }
        .item_desc {
            // @include dflex-override(column, flex-start, flex-start);
            @include dflex-override(column, flex-start, center);
            h2 {
                margin: 10px 20px 0px 20px !important;
            }
            .item_info {
                @include dflex-override(column, space-between, flex-start);
                height: fit-content !important;
                margin-left: 0px !important;
                &_main {
                    @include dflex-override(column, space-between, space-between);
                    width: 100% !important;
                    // padding: 0px !important;
                    // margin: 15px !important;
                    border-right: 0px !important;
                    border-bottom: 1px solid #4C4C50;
                    padding: 30px !important;
                    margin-bottom: 10px;
                }
                &_action {
                    @include dflex-override(column, space-between, flex-start);
                    width: 100% !important;
                }
                &_price {
                    // @include dflex-override(column, flex-start, flex-start);
                    margin-left: 0px !important;
                    // padding-left: 15px !important;
                    padding: 5px 30px !important;
                }
                .btn_left_noradius {
                    border-bottom-left-radius: 20px;
                }
            }
        }
    }
    .item_data {
        &_key {
            width: 30% !important;
        }
        &_val {
            width: 70% !important;
        }
    }
}

@media (min-width: 1200px) {
    .item_page {
        width: 1000px !important;
        margin: auto !important;
    }
}

.item_page {
    @include dflex(column, flex-start, flex-start);
    width: 100%;
    &_container {
        width: 100%;
        padding: 0px;
    }

    .item_header {
        @include dflex(row, flex-start, flex-start);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: none;
        background: $sidebar-bg;
        width: 100%;

        & > .item_background {
            background-image: url(../../assets/images/item/item_bg.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            height: 217px;
            width: 217px;
            // height: 290px;
            // width: 290px;
            padding: 0px;

            // -webkit-border-radius: 20px 0 0 20px;
            // -moz-border-radius: 20px 0 0 20px;
            // border-radius: 20px 0 0 20px;
            @include border-radius(20px 0 20px 0);

            border: none;

            & > .item_image {
                // height: 290px;
                // width: 290px;
                height: 217px;
                width: 217px;

                // -webkit-border-radius: 20px 20px 0 0;;
                // -moz-border-radius: 20px 20px 0 0;;
                // border-radius: 20px 20px 0 0;;
                @include border-radius(20px 0 20px 0);
                border: none;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;

            }
        }

        & > .item_desc {
            @include dflex(column, flex-start, flex-start);
            width: 100%;
            // padding: 0px;
            h2 {
                font-family: Metropolis-Medium, sans-serif;
                font-weight: 500;
                font-size: 30px;
                letter-spacing: 0;
                color: $white;
                // margin: 30px 40px;
                margin: 30px 20px;
            }

            & > .item_info {
                @include dflex(row, flex-start, flex-start);
                // width: 100%;
                width: -webkit-fill-available;
                // margin-left: 40px;
                margin-left: 5px;
                height: 194px;  //// ????
                // height: 230px; // ???

                .loader-view {
                    background: $sidebar-bg;
                }

                & > .item_info_main {
                    padding: 0 15px 5px 15px;
                    // padding: 15px;
                    width: 50%;
                    height: 100%;
                    border-right: 1px solid #4C4C50;

                    & > .item_info_prop {
                        @include dflex(row, space-between, flex-start);
                        label {
                            font-family: Metropolis-Medium, sans-serif;
                            font-weight: 500;
                            font-size: 15px;
                            color: rgba(255,255,255,0.35);
                            letter-spacing: 0;
                            line-height: 31px;
                            margin: 0px;
                        }

                        span {
                            font-family: Metropolis-Medium, sans-serif;
                            font-weight: 500;
                            font-size: 15px;
                            color: #35A5FF;
                            letter-spacing: 0;
                            text-align: right;
                            line-height: 31px;
                        }
                    }
                }

                & > .item_info_action {
                    width: 50%;
                    @include dflex(column, space-between, flex-start);
                    padding: 0px;
                    height: 100%;
                    & > .item_info_price {
                        @include dflex(column, flex-start, flex-start);
                        margin-left: 30px;
                        label {
                            opacity: 0.35;
                            font-family: Metropolis-Medium, sans-serif;
                            font-weight: 500;
                            font-size: 15px;
                            color: #FFFFFF;
                            letter-spacing: 0;
                            margin-bottom: 0px;
                        }
                        p {
                            font-family: Metropolis-Regular, sans-serif;
                            font-weight: 500;
                            font-size: 22px;
                            color: #FFFFFF;
                            letter-spacing: 0;
                            line-height: 38px;
                            width:100%;
                            margin-bottom: 5px;

                            span {
                                color: $author-color;
                            }
                        }
                    }
                    & > .item_info_buttons {
                        @include dflex(row, flex-end, flex-end);
                        width:100%;
                        button {
                            // border-radius: 30.5px;
                            font-family: Metropolis-Bold, sans-serif;
                            font-weight: 700;
                            font-size: 13px;
                            // color: #FFFFFF;
                            letter-spacing: 0;
                            line-height: 25px;

                            width: 100%;
                            height: 61px;
                            border:none;
                            outline: none;

                            // margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .item_section_header {
        & > h3 {
            font-family: Metropolis-Medium, sans-serif;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;

            margin: 50px 0 20px 0;
        }
    }
    .media_data {
        @include dflex(row, center, flex-start);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: none;
        overflow: hidden;
        background: #3E3E4B;
        // height: 170px;
        @media (min-width: 767px) {
            height: 170px;
        }

        .media_image {
            // width: 150px;
            // height: 130px;
            // height: 150px;
            // width: 300px;
            margin: 0 16px;
            height: 170px;
        }
    }

    .item_data {
        @include dflex(column, flex-start, flex-start);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: none;
        overflow: hidden;

        // background: $sidebar-bg;
        // @include dflex(column, flex-start, flex-start);
        &_row {
            @include dflex(row, flex-start, flex-start);
            width: 100%;
            background: $sidebar-bg;
            // @include dflex(row, flex-start, flex-start);
            // padding: 20px;
        }
        &_key {
            background: $sidebar-bg;

            padding-left: 10px;
            // padding-left: 30px;

            font-family: Metropolis-Medium, sans-serif;
            font-weight: 500;
            font-size: 15px;
            color: rgba(255,255,255,0.35);
            letter-spacing: 0;
            line-height: 31px;
            width:20%;
        }
        &_val {
            // padding: 20px 0 0 20px;
            background: $light-bg;
            // padding: 5px 0 5px 15px;
            padding-left: 20px;
            width: 80%;
            word-break: break-all;
            font-family: Metropolis-Medium, sans-serif;
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 31px;
        }
    }
}