

@media (min-width: 1200px) {
    .transactions {
        // width: 1000px !important;
        margin: auto !important;
    }
}

.transactions {
    & > .container {
        @include dflex(column, flex-start, flex-start);
    }

    h1 {
        color: $white;
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0;
        margin-bottom: 45px;
    }

    .item_transfer {
        // @include dflex(row, space-between, flex-start);
        // @include dflex(row, flex-start, flex-start);
        @media (max-width: 576px) {
            @include dflex(column, flex-start, flex-start);
        }
        @media (min-width: 577px) and (max-width: 991px) {
            @include dflex(row, space-between, flex-start);
            height: 130px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            @include dflex(row, space-between, flex-start);
            height: 130px;
        }
        @media (min-width: 1200px) {
            @include dflex(row, space-between, flex-start);
            height: 130px;
        }
        width: 100%;
        // height: 130px;
        border-radius: 20px;
        background: $sidebar-bg;
        margin-bottom: 20px;
        color: $white;


        .item_left {
            @include dflex(row, flex-start, flex-start);
            // width: 60%;
            & > .item_background {
                background-image: url(../../assets/images/item/item_bg.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;

                height: 130px;
                width: 130px;
                padding: 0px;

                @media (max-width: 576px) {
                    -webkit-border-radius: 20px 0 20px 0;
                    -moz-border-radius: 20px 0 20px 0;
                    border-radius: 20px 0 20px 0;
                }
                @media (min-width: 577px) {
                    -webkit-border-radius: 20px 0 0 20px;
                    -moz-border-radius: 20px 0 0 20px;
                    border-radius: 20px 0 0 20px;
                }

                border: none;

                & > .item_image {
                    height: 130px;
                    width: 130px;

                    -webkit-border-radius: 20px 20px 0 0;;
                    -moz-border-radius: 20px 20px 0 0;;
                    border-radius: 20px 20px 0 0;;
                    border: none;

                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }
            & > .item_desc {
                @include dflex(column, flex-start, flex-start);
                // margin: 30px 0 0 30px;
                // min-width: 60%;
                @media (max-width: 576px) {
                    margin: 10px 0 0 30px;
                    // margin: 20px 0 0 10px;
                    min-width: 130px;
                }
                @media (min-width: 577px) and (max-width: 991px) {
                    margin: 10px 0 0 30px;
                    // margin: 20px 0 0 20px;
                    min-width: 230px;
                }
                @media (min-width: 992px) and (max-width: 1199px) {
                    // margin: 30px 0 0 30px;
                    margin: 10px 0 0 30px;
                    min-width: 230px;
                }
                @media (min-width: 1200px) {
                    // margin: 30px 0 0 30px;
                    margin: 10px 0 0 30px;
                    min-width: 50%;
                }

                h2 {
                    font-family: Metropolis-Bold, sans-serif;
                    font-weight: 700;
                    word-break: break-all;
                    // font-size: 19px;
                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                    @media (min-width: 577px) and (max-width: 991px) {
                        font-size: 16px;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 19px;
                    }
                    @media (min-width: 1200px) {
                        font-size: 19px;
                    }
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin-bottom: 5px;
                }
                h4 {
                    /* Author: johnycage: */
                    font-family: Metropolis-Regular, sans-serif;
                    font-weight: 500;
                    // font-size: 13px;
                    @media (max-width: 576px) {
                        font-size: 10px;
                    }
                    @media (min-width: 577px) and (max-width: 991px) {
                        font-size: 11px;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 13px;
                    }
                    @media (min-width: 1200px) {
                        font-size: 13px;
                    }
                    color: rgba(255,255,255,0.30);
                    letter-spacing: 0;
                    // line-height: 38px;
                    line-height: 18px; // TODO: look if not too samall
                    margin: 0;
                    span {
                        color: $white;
                        opacity: 0.3;
                    }
                    a {
                        color: $author-color;
                    }
                }
            }
        }
        .item_right {
            @include dflex(column, flex-start, flex-start);
            @media (max-width: 576px) {
                margin: 0 10px 0 auto;
            }
            // @media (min-width: 577px) and (max-width: 991px) {
            //     margin: 0 10px 0 auto;
            //     min-width: 230px;
            // }
            // @media (min-width: 992px) and (max-width: 1199px) {
            //     min-width: 230px;
            // }
            // width: 40%;
            & > .item_price {
                @include dflex(column, flex-start, flex-start);
                // margin: 30px 0 0 0px;
                // margin: 20%;
                // min-width: 60%;
                // @media (max-width: 576px) {
                //     margin: 0 10px 0 auto;
                // }
                // @media (min-width: 577px) and (max-width: 991px) {
                //     margin: 0 10px 0 auto;
                //     min-width: 230px;
                // }
                // @media (min-width: 992px) and (max-width: 1199px) {
                //     min-width: 230px;
                // }
                // @media (min-width: 1200px) {
                //     h3 {
                //         width:250px;
                //     }
                // }

                h3 {
                    font-family: Metropolis-Bold, sans-serif;
                    font-weight: 700;
                    font-size: 16px;

                    @media (max-width: 576px) {
                        padding: 0;
                        // font-size: 12px;
                        // margin: 0 10px 0 auto;
                    }
                    @media (min-width: 577px) {
                        padding: 50px 50px 50px 10px;
                        // font-size: 14px;
                    }
                    // @media (min-width: 992px) and (max-width: 1199px) {
                    //     padding: 50px 50px 50px 10px;
                    //     // font-size: 16px;
                    // }
                    // @media (min-width: 1200px) {
                    //     padding: 50px 50px 50px 10px;
                    //     // font-size: 16px;
                    // }
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin-bottom: 5px;
                }
                .green_price {
                    color: $green-price;
                }
                .red_price {
                    color: $red-price;
                }
            }
            & > .item_action {
                width: 100%;
                @include dflex(row, flex-end, flex-end);
                // margin: 45px 35px 0 0;
                margin: 28px 0px 0 0;
            }
        }
    }
}

.history_logs {
    ul {
        padding: 0;
    }
}

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }
