@import "global/_global-dir";
@import "base/_base-dir";
@import "layout/_layout-dir";
@import "components/_comp-dir";


* {
  outline: none !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

#app-site,
.app-main {
  display: flex;
  width: 100%;
  // height: 100%;
  height: unset;
}
.app-container {
  @include display-flex(flex, row, nowrap);
  width: 100%;
}

.app-main-container {
  // background: #202126;
  background: $bg-light;
}

.app-main-content {
  padding-bottom: 70px;
}

html {
  height: unset;
}

body {
  // overflow: hidden;
  // overflow: auto;
  height:unset;
  margin: 0;

  line-height: 1.5;

  font: {
    // family: 'Material-Design-Iconic-Font';
    size: 15px;
    // font-size: 14px;
    // line-height: 1.2;
    // font-family: Montserrat,sans-serif;
    // font-family: 'Montserrat', sans-serif;
    family: Metropolis, sans-serif;
    weight: 500;
    // font-family: Metropolis, sans-serif;
    // font-weight: 500;
  }
  // --font-family-sans-serif: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  // --font-family-monospace: Montserrat, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

// NO SCROLLBAR AT ALL scroll bar
// ::-webkit-scrollbar {
//   width: 0px;  /* Remove scrollbar space */
//   background: transparent;  /* Optional: just make scrollbar invisible */
// }

// NO SCROLLBAR AT ALL scroll bar - THIS IS SIDEBAR SCROLLBAR
// .scrollbar {
//   div:not(:first-child) {
//     opacity: 0 !important;
//     display: none !important;
//   }
// }

// ::-webkit-scrollbar {
//   width: 12px;  /* Remove scrollbar space */
//   /* Rectangle 6: */
//   background: #262630;
//     /* Rectangle 6: */
//   background: #464653;
//   border-radius: 6px;
//   // background: transparent;  /* Optional: just make scrollbar invisible */
// }
html {
  --scrollbarBG: #2C2C37;
  // --thumbBG: #353540;
  --thumbBG: #464653;
}
body::-webkit-scrollbar {
  width: 14px;
}
body {
  scrollbar-width: 14px;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 2px solid var(--scrollbarBG);
}

// This works for me with simple CSS properties: (NO SCROLLBAR)
// .container {
//     -ms-overflow-style: none;  // Internet Explorer 10+
//     scrollbar-width: none;  // Firefox
// }
// .container::-webkit-scrollbar {
//     display: none;  // Safari and Chrome
// }

li {
  list-style-type: none;
}

a {
  color: #35A5FF;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #35A5FF;
    text-decoration: none;
    background-color: transparent;
  }
}

.market_section {
  overflow-y: scroll;
}

// .infinite-scroll-component {
//   overflow: hidden;
// }