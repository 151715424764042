.btn {

    /* SELL: */
    font-family: Metropolis-Bold, sans-serif;
    font-weight: 700;
    font-size: 13px;
    // color: #FFFFFF;
    color: $white;
    letter-spacing: 0;
    line-height: 25px;



    // display: inline-block;
    text-align: center;
    // font-size: 16px;
    // font-weight: 600;
    // width: 100%;
    // min-height: 35px;
    // height: 35px;
    width: 100%;
    // padding-top: 8px;
    // padding-bottom: 16px;
    // line-height: 17px;
    // font-size: 10px;
    // background-size: 200% 100%;
    border-radius: 0 0 20px 0px;
    border: none;
    // margin: 0;
    // margin: 0px 0px 0px -10px; // Dunno why this makes proper fucking margins...
    // overflow: visible;
    overflow: hidden;

    @include dflex(row, center, center);

    // &:hover {
    //     background-position-x: 100%;
    //     box-shadow: 0 0 0 0 #45bcff, 0 0 0 0 #21408e;
    //     color: $white;
    // }


    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline:0;
        box-shadow: none;
    }
    &:active {
        outline: none;
        border: none;
    }

    & > img {
        // height:25px !important;
        // width:25px !important;
    }

    &_primary {
        width: 100%;
        background: $primary-btn;
        // padding-top: 8px;
        // padding-bottom: 8px;
        // line-height: 17px;
        // font-size: 10px;
        // font-size: 14px;
        // color: $white;
        // background: linear-gradient(264.88deg,#285de5,#25a9f3 50%,#285de5) 0 0;
        // box-shadow: 0 -1px 0 0 #45bcff, 0 1px 0 0 #21408e;
    }

    &_secondary {
        // color: #fff;
        // border-radius: 2px;
        border-radius: 0 0 0 20px;
        background: $secondary-btn;
        width:100%;
        // padding: 8px 10px;
        // min-width: 44px;
        // max-width: 50px;
        max-width: 68px;
        text-align: center;
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 25px;
    }

    &_gray {
        width: 100%;
        background: $sidebar-bg;
    }

    &_claim {
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        font-size: 13px;
        width: 100px;
        border-radius: 30.5px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 25px;

        &_wide {
            width: 150px;
        }
        &_wider {
            width: 200px;
        }
    }


    &_delete {
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        font-size: 13px;
        // width: 100px;
        width: 40px;
        border-radius: 30.5px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 25px;

        // &_wide {
        //     width: 150px;
        // }
        // &_wider {
        //     width: 200px;
        // }
    }

    &_left_noradius {
        border-bottom-left-radius: 0px;
    }

    &_right_noradius {
        border-bottom-right-radius: 0px;
    }

    &_left_radius {
        border-bottom-left-radius: 20px;
    }

    &_right_radius {
        border-bottom-right-radius: 20px;
    }

    &_even {
        width: 50%;
        max-width: 50%;
    }

    &_cancel {
        background: $cancel-btn;
        color: $cancel-color;
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        font-size: 15px;
        &:hover {
            color: $cancel-color;
        }
    }

    &_item_page_bg {
        background: $sidebar-bg;
    }

    &_double_radius {
        border-radius: 0 0 20px 20px;
    }

    &_lrg {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        border-radius: 0 0 20px 20px;
    }

    &_font_sm {
        font-size: 11px;
    }

    &_lrg_noradius {
        width: 100%;
        max-width: 100%;
        margin: 0px;
    }

    &_mid {
        // margin-top: 10px;
        width: 200px;
        height: 25px;
    }

    &_sm {
        height: 25px;
        max-width: 65px;
    }

    &_s_round {
        color: #fff;
        border-radius: 25px;
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        width: unset;
        font-size: 11px;
        line-height: 14px;
        padding: 10px 20px;
        margin: 2px 5px;
    }

    &_standalone_primary {
        background: $primary-btn;
        border-radius: 30.5px;
        width: 213px;
        height: 50px;
        font-family: Metropolis-Bold;
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 25px;
        // :disabled {
        //     background: $primary-btn;
        //     color: rgba(255,255,255,0.50);
        // }
    }


    &_standalone_secondary {
        border: 1px solid rgba(255,255,255,0.20);
        border-radius: 30.5px;
        width: 213px;
        height: 50px;
        font-family: Metropolis-Bold, sans-serif;
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 25px;
    }



    // border-top-color: initial;
    // border-top-style: none;
    // border-top-width: initial;
    // border-right-color: initial;
    // border-right-style: none;
    // border-right-width: initial;
    // border-bottom-color: initial;
    // border-bottom-style: none;
    // border-bottom-width: initial;
    // border-left-color: initial;
    // border-left-style: none;
    // border-left-width: initial;
    // border-image-source: initial;
    // border-image-slice: initial;
    // border-image-width: initial;
    // border-image-outset: initial;
    // border-image-repeat: initial;
}

// scroll to the top | back to the top
.ScrollUpButton__Toggled {
    border-radius: 10px;
}