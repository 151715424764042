

.logo {
    // margin: 50px auto 20px 30px; // THIS IS OLD LOGO (text based!)
    margin: 50px auto 20px 10px; // THIS IS NEW LOGO FROM SEBA
    a {
        font-family: Metropolis-ExtraBold, sans-serif;
        font-weight: 700;
        font-size: 27px;
        text-decoration: none;
        color: $white;
        &:hover {
            color: $white;
        }
        span {
            // font-family: 'Metropolis Light', sans-serif;
            font-family: Metropolis-Light, sans-serif;
            font-weight: 500;
            font-size: 11px;
            margin-left: 7px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.waxinventory_logo {
    @media screen and (max-width: 1199px) {
        height: 70px;
    }
    @media screen and (min-width: 1200px) {
        height: 130px;
    }
}