

@media (min-width: 1200px) {
    .contact {
        width: 1000px !important;
        margin: auto !important;
    }
}


.contact {
    & > .container {
        height: 60vh;
        @include dflex(column, center, center);
    }

    &_form {
        h3 {
            opacity: 0.6;
        }
        button {
            margin-top: 10px;
            width: 100%;
        }
        @media screen and (min-width: 577px) {
            width: 400px;
        }
        @media screen and (max-width: 576px) {
            width: 330px;
        }
        @include dflex(column, flex-start, center);

        .form_input {
            @media screen and (min-width: 577px) {
                width: 400px;
            }
            @media screen and (max-width: 576px) {
                width: 330px;
            }
        }
    }

}