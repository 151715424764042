

@media screen and (max-width: 760px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }
    .history_table {
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr:first-child {
            @include border-radius(20px 20px 0 0);
        }
        tr:last-child {
            @include border-radius(0 0 20px 20px);
        }

        td {
            /* Behave  like a "row" */
            border: none;
            // border-bottom: 1px solid #eee;
            position: relative;
            // padding-left: 50%;e
            // padding-top: 10%;
            padding: 10px 5px 5px 15px;
        }

        td:before {
            /* Now like a table header */
            // position: absolute;
            /* Top/left values mimic padding */
            // top: 6px;
            // left: 6px;
            // width: 45%;
            // padding-right: 10px;
            /* Action type: */
            font-family: Metropolis-Regular;
            font-size: 13px;
            color: rgba(255,255,255,0.30);
            letter-spacing: 0;
            // line-height: 38px;
            line-height: 21px;
            white-space: nowrap;
        }

        td:nth-of-type(1):before { content: "Action"; }
        td:nth-of-type(2):before { content: "Actors"; }
        td:nth-of-type(3):before { content: "Data"; }
        td:nth-of-type(4):before { content: "Date"; }
        td:nth-of-type(5):before { content: "View TX"; }
    }
// }
}

@media screen and (min-width: 760px) {
    .history_table {
        th {
            height: 50px;
        }
        tr {
            height: 50px;
        }
        td {
            padding: 5px 10px;
            // padding: 5px 20px;
        }
    }
}

.history_table {
    @include border-radius(20px);
    background: #2C2C37;
    width: 100%;
    // border-radius: 20px;

    th {
        background: #2C2C37;
        // height: 50px;
        @include border-radius(20px 20px 0 0);
    }
    tr:nth-child(even) {background: #2C2C37}
    tr:nth-child(odd) {background: #3E3E4B}
    // tr {
    //     height: 50px;
    // }
    tr:last-child td:first-child {
        @include border-radius(0 0 0 20px);
    }
    tr:last-child td:last-child {
        @include border-radius(0 0 20px 0);
    }

    th {
        padding: 5px 10px;
    }

    td ul {
        padding: 0;
        margin: 0;

        li {
            word-break: break-all;
            // background: $light-bg;
                // padding: 5px 0 5px 15px;
                // padding-left: 20px;
            display: flex;
            width:100%;

        }
    }
    div.flex_container {
        display: flex;
        width: 100%;
    }
    div.key {
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: rgba(255,255,255,0.35);
        letter-spacing: 0;
        line-height: 31px;
        width:25%;

        font-family: Metropolis-Regular;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
        letter-spacing: 0;
        line-height: 21px;
        white-space: nowrap;
    }
    div.val {
        width: 75%;
        word-break: break-all;
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 21px;
        // padding: 20px 0 0 20px;

    }
    // .tables tr:last-child td:first-child{
    //     /* bottom left radius styles here */
    //  }
    //  .tables tr:last-child td:last-child{
    //     /* bottom right radius styles here */
    //  }
}
.history_actors {
    div {
        width: 220px;
    }
}

.history_tx_icon {
    width: 25px;
    cursor: pointer;
    margin: 5px;
}
.history_arrow_icon {
    width: 18px;
    cursor: default;
    margin: 5px;
}

.history_account_name {
    cursor: pointer;
    text-decoration: none;
    color: $author-color;
    &:hover {
        text-decoration: none;
        color: $author-color;
    }
}



// /* Rectangle 4: */
// background: #2C2C37;
// border-radius: 20px;
// /* Rectangle 5: */
// background: #3E3E4B;
// /* TRANSFER: */
// font-family: Metropolis-Medium;
// font-size: 12px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* bohdanbohdan: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* waxinventory: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// transform: rotate(-180deg);
// background: #FFFFFF;
// /* 12/11/2019 13:21: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// background: #35A5FF;
// /* TRANSFER: */
// font-family: Metropolis-Medium;
// font-size: 12px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* cezarycezary: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* bohdanbohdan: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// transform: rotate(-180deg);
// background: #FFFFFF;
// /* 12/11/2019 13:21: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// background: #35A5FF;
// /* Rectangle 5: */
// background: #3E3E4B;
// /* CREATE: */
// font-family: Metropolis-Medium;
// font-size: 12px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* cezarycezary: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* 12/11/2019 13:21: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// background: #35A5FF;
// /* Action type: */
// font-family: Metropolis-Regular;
// font-size: 13px;
// color: rgba(255,255,255,0.30);
// letter-spacing: 0;
// line-height: 38px;
// /* Action data: */
// font-family: Metropolis-Regular;
// font-size: 13px;
// color: rgba(255,255,255,0.30);
// letter-spacing: 0;
// line-height: 38px;
// /* Date: */
// font-family: Metropolis-Regular;
// font-size: 13px;
// color: rgba(255,255,255,0.30);
// letter-spacing: 0;
// line-height: 38px;
// /* View Tx: */
// font-family: Metropolis-Regular;
// font-size: 13px;
// color: rgba(255,255,255,0.30);
// letter-spacing: 0;
// line-height: 38px;



// /* Rectangle 5: */
// background: #3E3E4B;
// /* TRANSFER: */
// font-family: Metropolis-Medium;
// font-size: 12px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* bohdanbohdan: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* waxinventory: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #35A5FF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// transform: rotate(-180deg);
// background: #FFFFFF;
// /* 12/11/2019 13:21: */
// font-family: Metropolis-Medium;
// font-size: 15px;
// color: #FFFFFF;
// letter-spacing: 0;
// line-height: 31px;
// /* Bitmap: */
// background: #35A5FF;