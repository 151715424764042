
.header {
    visibility: visible;
    z-index: 9001 !important; // over 9000 ! // we want this to be over anything!
    // z-index: 1400; // we want this to be over anything!
    height: 50px;
    background-color: $sidebar-bg !important;
    position: fixed !important;
}

.app-toolbar {
    position: relative;
    background: $sidebar-bg !important;
    min-height: 50px !important;
    & > .logo {
        visibility: visible;
        margin: 4px 0 0 15px !important;
        line-height: 22px !important;
        a {
            @include dflex(row, flex-end, baseline);
            font-family: Metropolis-ExtraBold, sans-serif;
            font-weight: 700;
            font-size: 22px;
            color: #FFFFFF;
            letter-spacing: 0;

            span {
                font-family: Metropolis-Light, sans-serif;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                letter-spacing: 0;
            }
        }
    }
}



// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) { ... }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }


// .header {
//     visibility: hidden;
// }


@media (min-width: 1200px) {
    .header {
        visibility: hidden;
    }
    .app-toolbar {
        & > .logo {
            visibility: hidden;
        }
    }
}







// /*Header Styles*/
// .jr-menu-icon {
//   outline: none !important;
//   @include justify-content(flex-start !important);
// //   @extend %size-30;
//   padding: 0 5px !important;
// //   @extend %jr-menu-icon;
//   margin-right: 15px;
// }

// .app-main-header {
//   //z-index: 1302 !important;
//   background-color: $app-primary !important;
//   color: $white !important;

//   .ellipse-shape {
//     position: absolute;
//     right: 0;
//     top: 0;
//     z-index: -1;
//     overflow: hidden;
//     height: 100%;
//     width: 560px;

//     &:after {
//       content: '';
//       display: block;
//       width: 870px;
//       position: absolute;
//       top: -20px;
//       right: -300px;
//       height: 280px;
//       z-index: 1;
//       background-color: rgba($white, 0.06);
//       @include border-radius(50% 0 0 50%);
//     }
//   }

//   & .search-bar input {
//     @include transition($transition-base);
//     background-color: rgba($white, 0.20);
//     color: $white;
//     & + .search-icon {
//       top: 0;
//       & i {
//         color: rgba($white, 0.8);
//       }
//     }

//     &:focus {
//       background-color: $white;
//       color: $body-color;
//       + .search-icon {
//         & i {
//           color: rgba($body-color, 0.8);
//         }
//       }
//     }
//   }
// }

// .app-logo img {
//   height: 38px;

//   @media screen and (max-width: 575px) {
//     height: 28px;
//   }
// }

// .app-toolbar-special {
//   & .search-bar {
//     width: 200px;

//     @media screen and (max-width: 991px) {
//       width: 280px;
//     }

//     @media screen and (max-width: 767px) {
//       width: 200px;
//     }
//   }
// }

// .app-toolbar {
//   position: relative;
//   z-index: 2;
//   min-height: $app-bar-height;
//   height: $app-bar-height;
//   max-height: $app-bar-height;

//   & .search-bar {
//     margin-left: 50px;
//     margin-right: auto;
//     max-width: 300px;
//     width: 280px;

//     @media screen and (max-width: 767px) {
//       margin-left: 40px;
//       width: 200px;
//     }
//   }
// }

// .app-header-horizontal .app-toolbar {
//   & .search-bar {
//     margin-left: 20px;
//     max-width: 320px;
//     width: 300px;

//     @media screen and (max-width: 1199px) {
//       width: 240px;
//       margin-right: 10px;
//     }

//     @media screen and (max-width: 1099px) {
//       width: 200px;
//     }

//     @media screen and (max-width: 767px) {
//       margin-left: 40px;
//       width: 180px;
//     }
//   }
// }

// .app-toolbar {
//   & .nav-searchbox {
//     & .search-bar {
//       margin-left: 0;
//       width: 100%;
//     }

//     & .search-bar input {
//       @include transition($transition-base);
//       background-color: $white;
//       color: $body-color;
//       & + .search-icon {
//         & i {
//           color: rgba($body-color, 0.8);
//         }
//       }

//       &:focus {
//         background-color: transparent;
//         color: $body-color;
//         + .search-icon {
//           & i {
//             color: rgba($body-color, 0.8);
//           }
//         }
//       }
//     }
//   }
// }

// .header-notifications {
//   margin-bottom: 0;

//   & li {
//     vertical-align: middle;

//     &.list-inline-item:not(:last-child) {
//       margin-right: 0;
//     }
//   }

//   & .app-notification {
//     &-menu {
//       position: relative;
//       @include display-flex();
//       @include align-items(center);
//       padding: 2px 24px 2px 8px;
//       @include border-radius($border-radius);
//       background-color: rgba($black, 0.15);
//       cursor: pointer;

//       & i + span {
//         margin-left: 4px;

//         @media screen and (max-width: 575px) {
//           display: none;
//         }
//       }

//       &:after {
//         content: '\f2f9';
//         font: {
//           family: Material-Design-Iconic-Font;
//         }
//         display: inline-block;
//         position: absolute;
//         top: 50%;
//         right: 8px;
//         @include translateY(-50%);
//       }

//       @media screen and (max-width: 575px) {
//         padding: 5px 24px 5px 4px;
//       }
//     }

//     & .dropdown-menu {
//       width: 210px;

//       @media screen and (max-width: 575px) {
//         right: auto !important;
//         left: 0 !important;

//         &:before,
//         &:after {
//           right: 0;
//           left: 8px;
//         }
//       }
//     }

//     & .jr-list-link {
//       @include display-flex(flex, column, nowrap);
//       @include align-items(center);
//       @include transition(all 0.5s ease);
//       @include border-radius($border-radius);
//       padding: 10px;
//       color: $gray-600;
//       border: 1px solid transparent;

//       @include hover-focus-active {
//         color: $app-primary;
//         border-color: $app-primary;
//         text-decoration: none;
//       }

//       & > i {
//         font-size: 22px;
//         & + .jr-list-text {
//           margin-top: 8px;
//         }
//       }
//     }
//   }
// }

// .quick-menu {
//   & .dropdown-menu {
//     padding: 5px 10px;
//     width: 350px;

//     & .jr-card-header {
//       margin: -5px -10px 5px;
//       padding: 10px 10px 10px 20px;
//       border-bottom: 1px solid $gray-300;
//       z-index: 10;

//       & .icon-btn:last-child {
//         margin-top: 0;
//         margin-right: 0;
//       }
//     }

//     &:after {
//       border-left: 5px solid transparent;
//       border-right: 5px solid transparent;
//       border-bottom: 5px solid $white;
//       top: -9px;
//       content: "";
//       height: 9px;
//       position: absolute;
//       right: 8px;
//       width: 10px;
//     }

//     &:before {
//       border-left: 5px solid transparent;
//       border-right: 5px solid transparent;
//       border-bottom: 5px solid $black;
//       top: -10px;
//       content: "";
//       height: 10px;
//       position: absolute;
//       right: 8px;
//       width: 10px;
//     }

//     @media screen and (max-width: 449px) {
//       width: 280px;
//     }

//     @media screen and (max-width: 359px) {
//       width: 240px;
//     }
//   }

//   & .messages-list .user-avatar {
//     position: relative;
//   }

//   & .messages-list .badge {
//     position: absolute;
//     left: -4px;
//     top: -4px;
//     height: 16px;
//     width: 16px;
//     line-height: 16px;
//     text-align: center;
//     padding: 0;
//   }
// }

// @media screen and (max-width: 575px) {
//   .nav-searchbox.quick-menu .dropdown-menu {
//     width: 200px;
//   }
// }

// @media screen and (max-width: 399px) {
//   .nav-searchbox.quick-menu .dropdown-menu {
//     width: 100px;
//   }
// }

// .messages-list.language-list ul li {
//   padding: 7px 6px 6px;
// }

// .messages-list ul li {
//   position: relative;
//   padding: 20px 10px 14px;
//   border-bottom: 1px solid $gray-300;

//   & .user-name a {
//     color: $app-primary;
//   }

//   & .sub-heading {
//     font-size: 11px;
//     margin-bottom: 6px !important;

//     &.mb-0 {
//       margin-bottom: 0 !important;
//     }
//   }

//   &:last-child {
//     border-bottom: 0 none;
//   }

//   & .jr-btn {
//     margin-right: 0 !important;
//   }

//   & .jr-btn-xs {
//     font-size: 11px !important;
//     padding: 5px !important;
//   }
// }

// .quick-menu {
//   & .icon-btn {
//     color: $white;
//     font-size: 20px !important;
//     padding: 6px;
//   }

//   & .icon-alert {
//     position: relative;

//     &:after {
//       display: block;
//       position: absolute;
//       content: "";
//       right: 0;
//       top: 0;
//       z-index: 10;
//       width: 8px;
//       height: 8px;
//       background-color: $danger;
//       @include border-radius(50%);
//     //   @extend %pulse-effect;
//     }
//   }

//   & i.animated.infinite {
//     -moz-animation-iteration-count: 50;
//     -webkit-animation-iteration-count: 50;
//     -o-animation-iteration-count: 50;
//     animation-iteration-count: 50;
//   }
// }

// .quick-menu {
//   & .dropdown-menu {
//     display: block;
//     visibility: hidden;
//     left: auto !important;
//     right: 0 !important;
//     opacity: 0;
//     transform: translate3d(0px, 100px, 0px) !important;
//     @include transition(all 200ms linear 0ms);
//   }

//   &.show .dropdown-menu {
//     opacity: 1;
//     transform: translate3d(0px, 30px, 0px) !important;
//     visibility: visible;
//   }
// }

// .user-nav .quick-menu {
//   & .dropdown-menu {
//     width: 200px;
//     padding: 0 0 15px;

//     &:after {
//       border-bottom-color: $body-bg;
//     }
//   }

//   & .user-profile {
//     position: relative;
//     padding: 20px 25px;
//     background-color: $body-bg;
//     margin-bottom: 15px;
//     @include border-radius(0.25rem 0.25rem 0 0);
//     @include display-flex();

//     & .user-avatar {
//       margin-right: 0;
//     }
//   }
// }

// .dropdown-item[class*="text-"] {

//   &.active,
//   &:active {
//     background-color: transparent;
//   }
// }
