

@media (min-width: 1200px) {
    .transfers {
        width: 1000px !important;
        margin: auto !important;
    }
}

.transfers {
    & > .container {
        @include dflex(column, flex-start, flex-start);
    }

    h1 {
        color: $white;
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0;
        margin-bottom: 45px;
    }

    .item_transfer {
        @include dflex(row, flex-start, flex-start);
        width: 100%;
        height: 130px;
        border-radius: 20px;
        background: $sidebar-bg;
        margin-bottom: 20px;
        color: $white;

        & > .item_background {
            background-image: url(../../assets/images/item/item_bg.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            height: 130px;
            width: 130px;
            padding: 0px;

            -webkit-border-radius: 20px 0 0 20px;
            -moz-border-radius: 20px 0 0 20px;
            border-radius: 20px 0 0 20px;
            border: none;

            & > .item_image {
                height: 130px;
                width: 130px;

                -webkit-border-radius: 20px 20px 0 0;;
                -moz-border-radius: 20px 20px 0 0;;
                border-radius: 20px 20px 0 0;;
                border: none;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }
        }
        & > .item_desc {
            @include dflex(column, flex-start, flex-start);
            // margin: 30px 0 0 30px;
            margin: 10px 0 0 30px;
            // min-width: 60%;
            @media (max-width: 576px) {
                min-width: 130px;
            }
            @media (min-width: 577px) and (max-width: 991px) {
                min-width: 230px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
                min-width: 230px;
            }
            @media (min-width: 1200px) {
                min-width: 60%;
            }

            h2 {
                font-family: Metropolis-Bold, sans-serif;
                font-weight: 700;
                // font-size: 19px;
                @media (max-width: 576px) {
                    font-size: 14px;
                }
                @media (min-width: 577px) and (max-width: 991px) {
                    font-size: 16px;
                }
                @media (min-width: 992px) and (max-width: 1199px) {
                    font-size: 19px;
                }
                @media (min-width: 1200px) {
                    font-size: 19px;
                }
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 5px;
            }
            h4 {
                /* Author: johnycage: */
                font-family: Metropolis-Regular, sans-serif;
                font-weight: 500;
                font-size: 13px;
                color: rgba(255,255,255,0.30);
                letter-spacing: 0;
                // line-height: 38px;
                line-height: 18px; // TODO: look if not too samall
                margin: 0;
                span {
                    color: $white;
                    opacity: 0.3;
                }
                a {
                    color: $author-color;
                }
            }
        }
        & > .item_action {
            width: 100%;
            @include dflex(row, flex-end, flex-end);
            margin: 45px 35px 0 0;
        }
    }

    .claim_transfers_all {
        width: 100%;
        // text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }
