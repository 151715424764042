/*Typography Styles*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: $headings-color;
}

[class*="text-"] h1, [class*="text-"] .h1 {
  color: inherit;
}

[class*="text-"] h2, [class*="text-"] .h2 {
  color: inherit;
}

[class*="text-"] h3, [class*="text-"] .h3 {
  color: inherit;
}

[class*="text-"] h4, [class*="text-"] .h4 {
  color: inherit;
}

[class*="text-"] h5, [class*="text-"] .h5 {
  color: inherit;
}

[class*="text-"] h6, [class*="text-"] .h6 {
  color: inherit;
}

.text-strikethrough {
  text-decoration: line-through !important;
}

h1 {
  font-family: Metropolis-Medium, sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: #FFFFFF;
  letter-spacing: 0;
}
h3 {
  font-family: Metropolis-Medium, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
}

h1.page_header {
  margin: 15px 0 20px 5px;
}

.height100 {
  height: 100%;
}

// .desc-list {
//   margin-bottom: 0;
//   li {
//     padding: $jr-card-padding;
//     border-bottom: 1px solid $gray-500;
//     margin-left: -$jr-card-margin;
//     margin-right: -$jr-card-margin;

//     &:last-child {
//       border-bottom: 0 none;
//     }

//     & .card-title {
//       margin-bottom: 6px;
//     }

//   }

//   &.even-odd {
//     li {
//       border-bottom: 0 none;

//       &:nth-child(2n) {
//         background-color: $gray-300;
//       }
//     }
//   }
// }

// // Font Weight class
// .jr-font-weight-thin {
//   font-weight: $font-weight-thin;
// }

// .jr-font-weight-light {
//   font-weight: $font-weight-light;
// }

// .jr-font-weight-normal {
//   font-weight: $font-weight-normal;
// }

// .jr-font-weight-medium {
//   font-weight: $font-weight-medium;
// }

// .jr-font-weight-bold {
//   font-weight: $font-weight-bold;
// }

// .jr-font-weight-black {
//   font-weight: $font-weight-black;
// }

// /*Font size Classes*/
// /*8px*/
// .jr-fs-xxs {
//   font-size: 0.5rem !important;
// }

// /*10px*/
// .jr-fs-xs {
//   font-size: 0.625rem !important;
// }

// /*11px*/
// .jr-fs-11 {
//   font-size: 0.6875rem !important;
// }

// /*12px*/
// .jr-fs-sm {
//   font-size: $font-size-sm !important;
// }

// /*13px*/
// .jr-fs-13 {
//   font-size: 0.8125rem !important;
// }

// /*14px*/
// .jr-fs-md {
//   font-size: $font-size-base !important;
//   line-height: 1;
// }

// /*15px*/
// .jr-fs-15 {
//   font-size: 0.9375rem !important;
// }

// /*16px*/
// .jr-fs-lg {
//   font-size: 1rem !important;
//   line-height: 1;
// }

// /*20px*/
// .jr-fs-xl {
//   font-size: 1.25rem !important;
//   line-height: 1;
// }

// /*24px*/
// .jr-fs-xxl {
//   font-size: 1.5rem !important;
//   line-height: 1;

//   @media screen and (max-width: 1199px) {
//     font-size: 1.25rem !important; //20px
//   }
// }

// /*28px*/
// .jr-fs-2xl {
//   font-size: 1.75rem !important;
//   line-height: 1;

//   @media screen and (max-width: 1199px) {
//     font-size: 1.25rem !important; //20px
//   }
// }

// /*30px*/
// .jr-fs-xxxl {
//   font-size: 1.875rem !important;
//   line-height: 1;

//   @media screen and (max-width: 1199px) {
//     font-size: 1.75rem !important; //28px
//   }

//   @media screen and (max-width: 991px) {
//     font-size: 1.5rem !important; //24px
//   }
// }

// /*40px*/
// .jr-fs-xlxl {
//   font-size: 2.5rem !important;
//   line-height: 1;

//   @media screen and (max-width: 1199px) {
//     font-size:  2rem !important; //32px
//   }

//   @media screen and (max-width: 991px) {
//     font-size: 1.75rem !important; //28px
//   }
// }

// .jr-fs-cp {

//   @media screen and (min-width: 1368px) {
//     font-size: 1rem !important; //16px
//     line-height: 1;
//   }
// }

@font-face {
  font-family: 'Metropolis Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Regular'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Regular.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Regular Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-RegularItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Light'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-ExtraLight.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Light';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Light'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Light.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Thin'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Thin.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Extra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Light Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-ExtraLightItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Light Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-LightItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Thin Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-ThinItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Medium'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Medium.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Semi Bold'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-SemiBold.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Bold'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Bold.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Bold Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-BoldItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Medium Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-MediumItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Semi Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Semi Bold Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-SemiBoldItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Bold'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-ExtraBold.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Extra Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Bold Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-ExtraBoldItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Black';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Black'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-Black.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}
@font-face {
  font-family: 'Metropolis Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Black Italic'), url('https://www.cufonfonts.com/cdn/webfont/metropolis-2/Metropolis-BlackItalic.woff?token=NY25nN8DZAzlXGZT8gFiPkZxTUrY0scR') format('woff');
}