
// .login-container {
//     position: relative;
//     height: 100%;
//   }

.app-login-container {
    position: relative;
    max-width: 680px;
    height: 100vh;
    width: 94%;
    margin: 0 auto;
    background-color: $body-bg;

    & .loader-view {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 0;
        bottom: 0;
        z-index: 2;
        background-color: $body-bg;
    }
}

.app-login-main-content {
    background-color: $sidebar-bg;
    @include dflex(column, flex-start, flex-start);
    @include box-shadow($shadow);
    @include border-radius(20px);
    // border-radius: 20px;
    font-size: 14px;
    overflow: hidden;
    width: 370px;

    & > .login-border {
        border-top: 5px solid $author-color;
        width:100%;
    }

    & > .logo {
        // margin: 20px 30px 70px 30px; // OLD TEXT LOGO
        margin: 0px auto; // NEW PNG LOGO
    }
    & > h1 {
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;
        font-size: 35px;
        color: $white;
        letter-spacing: 0;
        line-height: 38px;
        margin-left: 30px;
    }
    & > span {
        margin-left: 30px;
        opacity: 0.35;
        font-family: Metropolis-Medium, sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: $white;
        letter-spacing: 0;
    }
}


.app-login-content {
    // margin-top: 50px; // TOO BIG
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    @include dflex(column, center, center);
    & > .login-option {
        cursor: pointer;
        margin: 5px 15px;
        background-color: $body-bg;
        border-radius: 10px;
        width: 90%;
        height: 50px;
        @include dflex(row, center, center);

        &:last-child {
            margin-bottom: 10px;
        }

        font-family: Metropolis-Regular, sans-serif;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 25px;
        color: $white;
    }
}

.auto_login_container {
    margin-top: 10px;
    label {
        span:last-child {
            margin-left: 10px;
        }
    }
}