

.modal {
    @include dflex(column, flex-start, flex-start);
    position: relative;
    margin: 15vh auto;
    width: 370px;
    height: fit-content;
    z-index: 1300;
    background: $bg-dark;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    border-top: 1px solid #687585; /// ????


    &_overlay {
        display: flex;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: $bg-overlay;
        z-index: 1250; // so its before menu;
    }

    &_container {
        @include dflex(column, flex-start, flex-start);
        width: 370px;
    }

    &_header {
        height: 130px;
        @include dflex(row, flex-start, flex-start);

        .modal_item_bg {
            background: url(../../assets/images/item/item_bg.png);
            background-size: 130px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;


            // -webkit-border-radius: 20px 20px 0 0;;
            // -moz-border-radius: 20px 20px 0 0;;
            // border-radius: 20px 20px 0 0;;
            border: none;
            width: 130px;

            .modal_item_img {
                height:130px;
                                //  height: 290px;
                        // width: 290px;
                // background-image: url(../../assets/images/item/item_bg.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 130px;
                width: 130px;
            }
        }

        & > &_name {
            padding: 30px 0px 0px 30px;
        }

        & > div {
            background: $bg-darker;
            width: 240px;
            height: 130px;
            // padding: 15px 0px 0px 20px;
            // padding: 30px 0px 0px 30px;
            & > h3 {
                font-family: Metropolis-Bold, sans-serif;
                font-weight: 700;
                // font-family: Metropolis-Regular; // should it be regular?
                font-size: 19px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 21px;
                // line-height: 38px;
                margin: 0;
            }
            & > h4 {
                font-family: Metropolis-Regular, sans-serif;
                font-weight: 500;
                font-size: 13px;
                color: rgba(255,255,255,0.30);
                letter-spacing: 0;
                line-height: 18px;
                margin: 0;
                span {
                    color: $white;
                    opacity: 0.3;
                }
                & > a {
                    color: $author-color !important;
                }
            }
        }
    }

    &_fields {
        @include dflex(column, flex-start, flex-start);
        width: 100%;
        padding: 15px 30px 30px 30px;

        font-family: Metropolis-Regular, sans-serif;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0;


        label {
            margin-top: 7px;
            color: $white;
        }
        div.price_input, div.text_input {
            height: 35px;
            display:flex;
            border-radius: 17.5px;
            width: 100%;
        }

        // div.name_input {

        // }

        // div.date_input {

        // }

    }


    & &_actions {
        height: 61px;
        width: 370px;
        @include display-flex(flex, row, nowrap);
        @include justify-content(space-between);
    }

    & &_radio {
        padding: 40px 30px 15px 30px;

        label {
            color: $white;
            margin-right: 45px;
        }
    }

    & &_checkbox {
        margin-top: 15px;
        width: 370px;

        @include dflex(row, flex-start, center);

        .slider {
            margin-right: 7px;
        }
    }
}


.modal_filters {
    &_header {
        padding: 30px 0px 30px 30px;
        width: 100%;
        background: #262630;
        h3 {
            font-family: Metropolis-Bold, sans-serif;
            font-weight: 700;
            font-size: 19px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 38px;
            margin: 0;
        }
    }
    div.input-range {
        // margin: 15px;
        // margin: 20px 0;
        margin: 20px 10px; // margin shifts input from left
        width: 93%; // shifted from left is centered
    }

    .input-range__slider{
        background: $author-color;
        border: 1px solid $author-color;
    }
    .input-range__track--active {
        background: $author-color;
    }
    span.input-range__label--max {
        display:none;
    }
    span.input-range__label--min {
        display:none;
    }

    div.price_range_inputs {
        height: 35px;
        display:flex;
        width: 100%;

        div.price_range_spacer {
            @include dflex(row, center, center);
            background: $input-wax;
            height: 35px;
            width: 64px;
            font-family: Metropolis-Bold, sans-serif;
            font-weight: 700;
            font-size: 12px;
            color: $white;
            letter-spacing: 0;
        }
        input.price_range_min {
            height: 35px;
            @include border-radius(17.5px 0 0 17.5px);
        }
        input.price_range_max {
            height: 35px;
            @include border-radius(0 17.5px 17.5px 0);
            text-align: left;
        }
    }
}

.margin10-0 {
    margin: 10px 0;
}

.any-christ {
    text-align: left;
    padding-left: 20px;
}
