
// Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) {
@media (max-width: 767px) {
    .items > ul > li {
        min-height: 0 !important;
        width: 100% !important;
        // margin-bottom: 15px !important;
        @include dflex(row, flex-start, flex-start);
        & > .items_background {
            width: 130px !important;
            height: 130px !important;
            @include border-radius-override(20px 0 0 20px);

            .items_image {
                width: 130px !important;
                height: 130px !important;
                @include border-radius-override(20px 0 0 20px);
            }
        }
    }
    .items {
        &_info {
            min-height: 0px !important;
            height: 100% !important;
            width: 100% !important;
            @include border-radius-override(0 20px 20px 0);
            h3 {
                font-size: 12px !important;
                line-height: 28px !important;
                // line-height: 22px !important;
            }
        }
        &_actions {
            width: 0px !important;
            height: 0px !important;
            visibility: hidden;
            bottom: 20px !important;
        }
        div.offer_price {
            bottom: 30px !important;
        }
        div.market_price {
            bottom: 10px !important;
        }
        // p.offer_price {
        //     bottom: 30px !important;
        // }
        div.auction_price {
            bottom: 10px !important;
        }
        // p.auction_price {
        //     bottom: 10px !important;
        // }
        div.price {
            font-size: 12px !important;
            line-height: 22px !important;
            // bottom: 10px !important;
        }
        // p {
        //     font-size: 12px !important;
        //     line-height: 22px !important;
        //     bottom: 10px !important;
        // }
        &_card {
            margin-bottom: 15px !important;
        }
    }
}

// @media (min-width: 768px) {
//     .item_extra {
//         &_info_mobile {
//             visibility: hidden;
//         }
//     }
// }

@media (min-width: 768px) and (max-width: 991px) {
    .marketplace {
        width: 666px;

    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .marketplace {
        width: 980px;
        margin: auto;
    }
}
@media (min-width: 1200px) and (max-width: 1550px) {
    .marketplace {
        width: 980px;
        margin: auto;
    }
}
@media (min-width: 1550px) and (max-width: 1900px) {
    .marketplace {
        width: 1300px;
        margin: auto;
    }
}
@media (min-width: 1900px) and (max-width: 2160px){
    .marketplace {
        width: 1620px;
        margin: auto;
    }
}
@media (min-width: 2160px) {
    .marketplace {
        width: 1920px;
        margin: auto;
    }
}

// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) { ... }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }



// Small devices (landscape phones, 576px and up)
// Medium devices (tablets, 768px and up)
// Large devices (desktops, 992px and up)
// @media (min-width: 576px) and (max-width: 1200px) {
//     .item_extra {
//         &_info_mobile {
//             visibility: hidden;
//         }
//         &_card {
//             visibility: hidden;
//         }
//     }
// }
// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }


.marketplace {
    margin: auto;
    & .items {
        ul {
            justify-content: space-between;
            display: flex;
            flex-wrap: wrap;
            padding: 0px;
            li {
                // background: $bg-dark;
                border-radius: 20px;
                // min-height: 451px; //todo: check sizes
                // width: 290px;
                width: 217px;
                margin-bottom: 30px;
                position: relative;

                .items_background {
                    background-image: url(../../assets/images/item/item_bg.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    z-index: 4;


                    // -webkit-border-radius: 20px 20px 0 0;;
                    // -moz-border-radius: 20px 20px 0 0;;
                    // border-radius: 20px 20px 0 0;;
                    // border: none;
                    @include border-radius(20px 20px 0 0);
                    // @include border-radius(20px 0 20px 0);
                    border: none;

                    cursor: pointer;

                    .items_image {

                        // height: 290px;
                        // width: 290px;
                        height: 217px;
                        width: 217px;
                        z-index: 5;

                        // -webkit-border-radius: 20px 20px 0 0;;
                        // -moz-border-radius: 20px 20px 0 0;;
                        // border-radius: 20px 20px 0 0;;
                        @include border-radius(20px 20px 0 0);
                        // @include border-radius(20px 0 20px 0);
                        border: none;

                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;

                        cursor: pointer;
                    }
                }

            }
        }

        &_info {
            // height: 100px;
            // min-height: 100px;  //without the price
            // @include border-radius(0 0 20px 20px);
            // min-height: 146px; // with the price (omg ;/)
            // min-height: 160px;

            // min-height: 140px;
            min-height: 70px;

            // width: 290px;
            width: 217px;
            // padding: 15px 0px 0px 20px; // b4 small card
            padding: 8px;
            z-index: 5;
            background: $bg-dark;
            h3 {
                font-family: Metropolis-Bold, sans-serif;
                // font-size: 16px; // B4 small card!
                font-size: 12px;
                font-weight: 700;
                color: #FFFFFF;
                letter-spacing: 0;
                // line-height: 38px;
                // line-height: 32px;
                // line-height: 21px;
                line-height: 15px;
                margin: 0;
            }
        }
        h4 {
            /* Author: johnycage: */
            font-family: Metropolis-Regular, sans-serif;
            font-weight: 500;
            // font-size: 13px;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.5);
            letter-spacing: 0;
            line-height: 18px; // TODO: look if not too samall
            margin: 0;
            span {
                color: $white;
                // opacity: 0.3; // GOOGLE DOESNT LIKE IT. FUCK.
                opacity: 0.5;
            }
            a {
                color: $author-color;
                &:hover {
                    color: $author-color;
                }
            }
        }

        div.price {
            // position: absolute;
            // bottom: 65px;

            /* 42.65469874 WAX: */
            font-family: Metropolis-Regular, sans-serif;
            font-weight: 500;
            // font-size: 16px; // ORIGINAL
            // font-size: 22px; // too big ??
            // font-size: 18px;
            // font-size: 16px; // b4 small card
            color: #FFFFFF;
            letter-spacing: 0;
            // line-height: 38px; // Too large for mobile
            // line-height: 32px;
            // line-height: 21px;
            // line-height: 23px;// b4 small card
            font-size: 12px;
            line-height: 15px;


            margin: 0;
            // @include dflex(row, flex-start, baseline);
            @include dflex(row, flex-end, baseline);
            span {
                color: $wax-color;
            }
        }
        // p {
        //     position: absolute;
        //     bottom: 65px;
        //     /* 42.65469874 WAX: */
        //     font-family: Metropolis-Regular, sans-serif;
        //     font-weight: 500;
        //     // font-size: 16px; // ORIGINAL
        //     // font-size: 22px; // too big ??
        //     font-size: 18px;
        //     color: #FFFFFF;
        //     letter-spacing: 0;
        //     // line-height: 38px; // Too large for mobile
        //     line-height: 32px;
        //     margin: 0;
        //     @include dflex(row, flex-start, baseline);
        //     span {
        //         color: $wax-color;
        //     }
        // }
        // div.offer_price {
        //     margin-top: 5px;

        //     // @include dflex(row, space-between, baseline);
        //     // margin-top: 35px;
        //     // bottom: 60px;

        // }
        // div.market_price {
        //     margin-top: 5px;
        //     margin-right: 10px;
        //     // margin-top: 35px;
        //     // bottom: 60px;
        // }
        // p.offer_price {
        //     margin-top: 35px;
        //     bottom: 65px;
        // }
        div.auction_price {
            margin-top: 5px;
            @include dflex(row, space-between, baseline);
            // @include dflex(row, flex-start, baseline);
            // margin-top: 15px;
            // bottom: 80px;
            .left_price {
                width: 100%;
                text-align: center;
                // border-right: 1px solid $body-bg;
                border-right: 1px solid $input-bg;

                i {
                    opacity: 0.5;
                    font-style: normal;
                }
            }
            .right_price {
                width: 100%;
                text-align: center;

                i {
                    opacity: 0.5;
                    font-style: normal;
                }
            }
        }
        // p.auction_price {
        //     margin-top: 15px;
        //     bottom: 85px;
        // }

        div.name_box {
            min-height: 30px;
        }

        &_actions {
            // height: 61px;
            // height: 45px;
            height: 50px;
            // width: 290px;
            // height: 217px;
            width: 217px;
            @include display-flex(flex, row, nowrap);
            @include justify-content(space-between);

        }
    }
}

.loadMore {
    width: 50%;
    margin: auto;
    & > button {
        font-family: Metropolis, sans-serif;
        font-weight: 500;
        height: 40px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: none;
    }
}

.icon {
    // width: 28px;
    width: 24px;
}
.input_icon {
    width: 18px;
}

.cart_icon {
    width: 28px;
}

.author_icon {
    width: 28px;
}
// display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: flex-end;
//     position: absolute;
//     top: 255px;
//     height: 37px;
//     background: #262630;
//     width: 100%;
//     border-radius: 20px 20px 0 0;

@media (max-width: 767px) {
    .item_extra {
        &_info_mobile {
            @include dflex(row, center, flex-end);
            position: absolute;
            // top: 80%;
            // height: 60px;
            top: 105%;
            height: 0px;
            z-index: 100;

            background: $darkest-bg;
            width: 100%;
            @include border-radius(0 0 20px 20px);
            & > .text {
                font-family: Metropolis-Regular, sans-serif;
                font-size: 9px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 38px;
            }
        }
        &_card {
            position: relative;
            margin-bottom: 50px !important;
        }
    }
}
@media (min-width: 768px) {
    .item_extra {
        &_info_mobile {
            @include dflex(row, center, flex-end);
            position: absolute;
            // top: 255px;
            
            // top: 180px; //this is bottom of picture
            // height: 37px; //this is bottom of picture

            // top: 320px;
            // top: 302px;
            top: 250px;
            height: 0px;

            background: $darkest-bg;
            width: 100%;
            @include border-radius(20px 20px 0 0);
            & > .text {
                font-family: Metropolis-Regular, sans-serif;
                font-size: 9px;
                color: #FFFFFF;
                letter-spacing: 0;
                // line-height: 38px;
                line-height: 0px;
            }
        }
    }
}

.item_props {
    position: relative;

    & > .author {
        position: absolute;
        @media screen and (max-width:991px) {
            top: -120px;
        }
        @media screen and (min-width: 992px) {
            top: -210px;
        }
        left: 10px;
        z-index: 2;
    }

    & > .author_list {
        top: -120px !important;
    }
}

.wear {
    position: relative;
    & > .pointer {
        position: absolute;
        // top: -16px;
        top: -8px;
        // top: 15px;
        // position: absolute;
        // width: 100%;
        z-index: 2;
        // text-align: left;
        font-family: Metropolis-Bold, sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 10px;
    }

    & > .progress {
        position: absolute;
        top: -8px;
        cursor: initial;

        height: 8px;
        margin: 0;
        overflow: hidden;
        width: 100%;

        & > .bar {
            float: left;
            height: 100%;
            // font-size: 12px;
            line-height: 20px;
            // color: #fff;
            // text-align: center;
            -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
            box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);

        }
        & > .new {
            width: 7%;
            background-color: green;
        }
        & > .minimal {
            width: 8%;
            background-color: #5cb85c;
        }
        & > .tested {
            width: 23%;
            background-color: #f0ad4e;
        }
        & > .worn {
            width: 7%;
            background-color: #d9534f;
        }
        & > .scared {
            width: 55%;
            background-color: #993a38;
        }
    }
}

.item_select {
    position: absolute;
    width: 32px;
    height: 25px;
    top: 10px;
    @media screen and (max-width: 767px) {
        left: 95px;
    }
    @media screen and (min-width: 768px) {
        left: 173px;
    }
}
.topSubNav_multiselect {
    @media screen and (max-width: 568px) {
        height: 105px;
        @include dflex(column, flex-start, flex-start);
    }
    .price_container {
        @include dflex(row, flex-start, flex-start);
    }
    .price_input {
        @include dflex(row, flex-start, flex-start);
    }
}

.vgo_mark {
    position: absolute;
    width: 100%;
    top: -30px;
    // left: 180px;
    @include dflex(row, space-between, baseline);
    cursor: initial;
    padding: 0 5px;
    .float {
        // color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        cursor: initial;
        padding: 0 5px;
    }
}

// .item_extra {
//     &_info_mobile {
//         @include dflex(row, center, flex-end);
//         position: absolute;
//         top: 80%;
//         height: 60px;
//         background: $darkest-bg;
//         width: 100%;
//         @include border-radius(0 0 20px 20px);
//         & > .text {
//             font-family: Metropolis-Regular, sans-serif;
//             font-size: 9px;
//             color: #FFFFFF;
//             letter-spacing: 0;
//             line-height: 38px;
//         }
//     }
//     &_card {
//         position: relative;
//         margin-bottom: 50px !important;
//     }
// }


// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) { ... }

// // Small devices (landscape phones, less than 768px)
// @media (max-width: 767px) { ... }

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991px) { ... }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199px) { ... }


// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) { ... }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }


