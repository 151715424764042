

.checkbox_label {
  @include dflex(row, flex-start, center);
}

.slider {
    position : relative ;
    display : inline-block;
    width : 32px;
    height : 20px;
    // background-color: #eee; // we dont want background
    border: 3px solid $input-bg;
    border-radius: 20px;
  }

  .slider::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    // background-color: white;
    background-color: $author-color;

    top: 1px; // TO GIVE AN EFFECT OF CIRCLE INSIDE SWITCH.
    left: 1px;
    transition: all 0.3s;
  }

  .checkbox:checked + .slider::after {
    left : 12px;
    background-color:$green-price;
  }
  .checkbox:checked + .slider {
    // background-color: #3a409b; // we dont want to change background on slide
  }
  .checkbox {
    width: 20px;
    display : none;
 }