


// Disable auto fill background change !
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input {
    background: $input-bg;
    font-family: Metropolis-Regular, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: $white; // ??? how to change placeholder color ?
    letter-spacing: 0;
    border-radius: 17.5px 0 0 17.5px; // TODO: make class for all rounded
    height: 35px;
    text-align: right;
    padding: 10px;
    width: 100%;
    border: 0px;

    &:focus {
        outline: none; // remove nasty blue border blueborder button focus buttonfocus
    }

    &::placeholer {
        color: rgba(256, 256, 256, 0.3);
    }

    &.text {
        text-align: left;
        border-radius: 17.5px;
    }
}
textarea {
    background: $input-bg;
    font-family: Metropolis-Regular, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: $white;
    letter-spacing: 0;
    border-radius: 17.5px;
    resize: none;
    text-align: right;
    padding: 10px;
    width: 100%;
    border: 0px;
    text-align: left;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 200px;

    &:focus {
        outline: none; // remove nasty blue border blueborder button focus buttonfocus
    }

    &::placeholer {
        color: rgba(256, 256, 256, 0.3);
    }
}
textarea::-webkit-scrollbar {
    width: 14px;
}
textarea {
    scrollbar-width: 14px;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
textarea::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
textarea::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 2px solid var(--scrollbarBG);
}


div.wax_input_label {
    @include dflex(row, center, center);
    background: $input-wax;
    height: 35px;
    width: 64px;
    font-family: Metropolis-Bold, sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: $white;
    letter-spacing: 0;
    border-radius: 0 17.5px 17.5px 0;
}
div.author_input_label {
    @include dflex(row, center, center);
    background: $input-wax;
    height: 35px;
    width: 95px;
    font-family: Metropolis-Bold, sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: $white;
    letter-spacing: 0;
    border-radius: 0 17.5px 17.5px 0;
}
select:not([multiple]){
    -webkit-appearance:none;
    -moz-appearance:none;
    background-position:right 50%;
    background-repeat:no-repeat;
    // background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: .5em;
    padding-right:1.5em;
    @include border-radius(17.5px 0 0 17.5px);
    border: 0px;
    width: 100%;
    background: $input-bg;
    text-align-last: end;

    color: $white;
}

.form_input {
    @include dflex(row, flex-start, flex-start);
    margin: 5px;
}