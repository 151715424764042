@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// $app-primary:   #353540 !default;
$app-primary:   #FFFFFF !default;
$primary:       $app-primary;
// $secondary:     #E91E63;
// $success:       #4caf50;
// $info:          #00bcd4;
// $warning:       #ff9800;
// $danger:        #f44336;
// $light:         #e8eaf6;
// $light-gray:    #808080;
// $dark:          $gray-800;
// $gray-default: #808080 !default;
// $sepia: #7c3535;
// $geekblue: #030852;

// $theme-colors: ();
// // stylelint-disable-next-line scss/dollar-variable-default
// $theme-colors: map-merge(
//       (
//               "primary":    $primary,
//               "secondary":  $secondary,
//               "success":    $success,
//               "info":       $info,
//               "warning":    $warning,
//               "danger":     $danger,
//               "light":      $light,
//               "dark":       $dark,
//               "sepia":      $sepia,
//               "geekblue":   $geekblue
//       ),
//       $theme-colors
// );



// Body
//
// Settings for the `<body>` element.

$body-bg:                   #353540;
$body-color:                #FFFFFF;
// $body-bg:                   #f4f4f7;
// $body-color:                #575757;


// Links
//
// Style anchor elements.

// $link-color:                              $app-primary;
// //$link-decoration:                         none !default;
// $link-hover-color:                        darken($app-primary, 10%);
//$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px
//) !default;
//
//@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;


// Components
//
// Define common padding and border radius sizes and more.

//$line-height-lg:              1.5 !default;
//$line-height-sm:              1.5 !default;
//
//$border-width:                1px !default;
$border-color:                #daddf1;

$border-radius:               0.375rem;
$border-radius-lg:            0.5rem;
$border-radius-sm:            0.125rem;
$border-radius-xl:            0.625rem !default;
$border-radius-xxl:           1.875rem !default;
$border-radius-circle:        50% !default;

//$rounded-pill:                50rem !default;

//Default shadow

$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
$shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-lg: 0 1px 8px -1px rgba(0, 0, 0, 0.20);
$shadow-xl: 0 0 16px 0px rgba(0, 0, 0, 0.20);
$avatar-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.20);

$box-shadow-sm:               $shadow-sm;
$box-shadow:                  $shadow;
$box-shadow-lg:               $shadow-lg;

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

//$caret-width:                 .3em !default;
//$caret-vertical-align:        $caret-width * .85 !default;
//$caret-spacing:               $caret-width * .85 !default;
//
//$transition-base:             all .2s ease-in-out !default;
//$transition-fade:             opacity .15s linear !default;
//$transition-collapse:         height .35s ease !default;
//
//$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
//$embed-responsive-aspect-ratios: join(
//                        (
//                                        (21 9),
//                                        (16 9),
//                                        (4 3),
//                                        (1 1),
//                        ),
//                $embed-responsive-aspect-ratios
//);

// Typography
//
// Font, line-height, and color for body text, headings, and more.
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
// @import url('/vendors/metropolis');

// stylelint-disable value-keyword-case
// $font-family-sans-serif: 'Roboto', sans-serif;
$font-family-sans-serif: Metropolis, sans-serif;

//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 0.875rem; // Assumes the browser default, typically `14px`
$font-size-lg: ($font-size-base + 0.25); //18px
$font-size-sm: ($font-size-base - 0.125); //12px

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-thin:            100 !default;
$font-weight-medium:          500 !default;
$font-weight-black:           900 !default;

//$font-weight-base:            $font-weight-normal !default;
//$line-height-base:            1.5 !default;

$h1-font-size:                1.5rem; //24px
$h2-font-size:                1.25rem; //20px
$h3-font-size:                1.125rem; //18px
$h4-font-size:                1rem; //16px
$h5-font-size:                0.875rem; //14px
$h6-font-size:                0.75rem; //12px

$headings-margin-bottom: ($h1-font-size / 2);
$headings-font-family: inherit;
$headings-font-weight: 400;
$headings-line-height: 1.2;
$headings-color: #252525;

//$display1-size:               6rem !default;
//$display2-size:               5.5rem !default;
//$display3-size:               4.5rem !default;
//$display4-size:               3.5rem !default;
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
$display-line-height:           $headings-line-height;

$lead-font-size:                1.25rem;
//$lead-font-weight:            300 !default;
//
//$small-font-size:             80% !default;
//
//$text-muted:                  $gray-600 !default;
//
//$blockquote-small-color:      $gray-600 !default;
//$blockquote-small-font-size:  $small-font-size !default;
//$blockquote-font-size:        $font-size-base * 1.25 !default;
//
//$hr-border-color:             rgba($black, .1) !default;
//$hr-border-width:             $border-width !default;
//
//$mark-padding:                .2em !default;
//
//$dt-font-weight:              $font-weight-bold !default;
//
//$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
//$nested-kbd-font-weight:      $font-weight-bold !default;
//
//$list-inline-padding:         .5rem !default;
//
//$mark-bg:                     #fcf8e3 !default;
//
//$hr-margin-y:                 $spacer !default;



// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.375rem;
$input-btn-padding-x:         0.75rem;
//$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .1rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      0.25rem;
$input-btn-padding-x-sm:      0.5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      0.6rem;
$input-btn-padding-x-lg:      1.2rem;
$input-btn-font-size-lg:      0.9375rem; //15px
$input-btn-line-height-lg:    $line-height-lg;

//$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               0.375rem;
$btn-padding-x:               0.75rem;
//$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $font-size-base;
$btn-line-height:             1.2;

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            0.9375rem;
$btn-font-size-sm:            $font-size-sm;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            0.6rem;
$btn-padding-x-lg:            1.2rem;
$btn-font-size-lg:            0.9375rem; //15px
//$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-padding-y-xs:            0.375rem;
$btn-padding-x-xs:            0.625rem;
$btn-font-size-xs:            0.625rem;

//$btn-border-width:            $input-btn-border-width !default;

//$btn-font-weight:             $font-weight-normal !default;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

//$btn-link-disabled-color:     $gray-600 !default;

//$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius;
$btn-border-radius-xs:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


//Button Variables
$btn-padding: $btn-padding-y $btn-padding-x !default;
$btn-padding-lg: $btn-padding-y-lg $btn-padding-x-lg !default;
$btn-padding-sm: $btn-padding-y-sm $btn-padding-x-sm !default;
$btn-padding-xs: $btn-padding-y-xs $btn-padding-x-xs !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

//$zindex-dropdown:                   1000 !default;
//$zindex-sticky:                     1020 !default;
//$zindex-fixed:                      1030 !default;
//$zindex-modal-backdrop:             1040 !default;
//$zindex-modal:                      1050 !default;
//$zindex-popover:                    1060 !default;
//$zindex-tooltip:                    1070 !default;


// // Navs

// //$nav-link-padding-y:                .5rem !default;
// //$nav-link-padding-x:                1rem !default;
// //$nav-link-disabled-color:           $gray-600 !default;
// //
// //$nav-tabs-border-color:             $gray-300 !default;
// //$nav-tabs-border-width:             $border-width !default;
// //$nav-tabs-border-radius:            $border-radius;
// //$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
// //$nav-tabs-link-active-color:        $gray-700 !default;
// //$nav-tabs-link-active-bg:           $body-bg !default;
// //$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
// //
// //$nav-pills-border-radius:           $border-radius !default;
// $nav-pills-link-active-color:       $component-active-color;
// $nav-pills-link-active-bg:          $component-active-bg;

// //$nav-divider-color:                 $gray-200 !default;
// //$nav-divider-margin-y:              $spacer / 2 !default;



// // Dropdowns
// //
// // Dropdown menu container and contents.

// $dropdown-min-width:                10rem;
// $dropdown-padding-y:                .5rem;
// //$dropdown-spacer:                   .125rem !default;
// $dropdown-font-size:                $font-size-base;
// $dropdown-color:                    $body-color;
// $dropdown-bg:                       $white;
// //$dropdown-border-color:             rgba($black, .15) !default;
// $dropdown-border-radius:            $border-radius;
// //$dropdown-border-width:             $border-width !default;
// $dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
// //$dropdown-divider-bg:               $gray-200 !default;
// //$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
// //$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
// //
// //$dropdown-link-color:               $gray-900 !default;
// //$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
// //$dropdown-link-hover-bg:            $gray-100 !default;

// $dropdown-link-active-color:        $component-active-color;
// $dropdown-link-active-bg:           $component-active-bg;

// //$dropdown-link-disabled-color:      $gray-600 !default;

// $dropdown-item-padding-y:           .25rem;
// $dropdown-item-padding-x:           1.5rem;

// //$dropdown-header-color:             $gray-600 !default;


// // Pagination

// //$pagination-padding-y:              .5rem !default;
// //$pagination-padding-x:              .75rem !default;
// //$pagination-padding-y-sm:           .25rem !default;
// //$pagination-padding-x-sm:           .5rem !default;
// //$pagination-padding-y-lg:           .75rem !default;
// //$pagination-padding-x-lg:           1.5rem !default;
// //$pagination-line-height:            1.25 !default;

// $pagination-color:                  $link-color;
// $pagination-bg:                     $white;
// //$pagination-border-width:           $border-width !default;
// //$pagination-border-color:           $gray-300 !default;

// $pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
// //$pagination-focus-outline:          0 !default;

// $pagination-hover-color:            $link-hover-color;
// //$pagination-hover-bg:               $gray-200 !default;
// //$pagination-hover-border-color:     $gray-300 !default;

// $pagination-active-color:           $component-active-color;
// $pagination-active-bg:              $component-active-bg;
// $pagination-active-border-color:    $pagination-active-bg;

// //$pagination-disabled-color:         $gray-600 !default;
// //$pagination-disabled-bg:            $white !default;
// //$pagination-disabled-border-color:  $gray-300 !default;



// Toasts

//$toast-max-width:                   350px !default;
//$toast-padding-x:                   .75rem !default;
//$toast-padding-y:                   .25rem !default;
//$toast-font-size:                   .875rem !default;
//$toast-color:                       null !default;
//$toast-background-color:            rgba($white, .85) !default;
//$toast-border-width:                1px !default;
//$toast-border-color:                rgba(0, 0, 0, .1) !default;
//$toast-border-radius:               .25rem !default;
//$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;
//
//$toast-header-color:                $gray-600 !default;
//$toast-header-background-color:     rgba($white, .85) !default;
//$toast-header-border-color:         rgba(0, 0, 0, .05) !default;


// Badges

//$badge-font-size:                   75% !default;
//$badge-font-weight:                 $font-weight-bold !default;
//$badge-padding-y:                   .25em !default;
//$badge-padding-x:                   .4em !default;
//$badge-border-radius:               $border-radius !default;
//
//$badge-transition:                  $btn-transition !default;
//$badge-focus-width:                 $input-btn-focus-width !default;
//
//$badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
//$badge-pill-border-radius:          10rem !default;



// List group
$list-group-border-radius:          $border-radius;


//App Bar Height
$app-bar-height: 70px !default;

//Footer Height
$footer-height: 50px !default;

//Side Nav Width
$side-nav-width: 250px !default;
$mini-drawer-width: 80px !default;
$sidebar-bg: #2C2C37 !default;
$bg-dark: $sidebar-bg;
$light-bg: #3E3E4B;
// $bg-overlay: #121219;
$bg-overlay: rgba(18, 18, 25, 0.8);

// $sidebar-bg: #252525 !default;
// $sidebar-text-color: #d4b1b1 !default;
$sidebar-text-color: #ffffff !default;
// $sidebar-bg-darken: darken($sidebar-bg, 3%);
$sidebar-bg-darken: #353540;
$bg-light: $sidebar-bg-darken;

$sidebar-hover-color: $white;
$sidebar-bg-darken-highlight: $app-primary;

// $author-color: #2671D1; // DARKER
$author-color: #35A5FF; // GOOGLE WILL LIKE IT ????
$blue-color: $author-color;
$primary-btn: #2671D1;
$secondary-btn: #205EAC;

$cancel-btn: #262630;
$darkest-bg: $cancel-btn;
$bg-darker: $cancel-btn;
$cancel-color: #FF0950;
$wax-color: #35A5FF;
$input-bg: #464653;
$input-wax: #3C3C48;

$red-price: #FF095A;
$green-price: #0DFF77;

//Default Image Location
$image_path: "../assets/images" !default;

// Motion
// https://material.google.com/motion/duration-easing.html#duration-easing-natural-easing-curves
$transition-bezier: .05, .74, .27, .99 !default; // for nav, quickview

$transition-ease: cubic-bezier(0.4, 0.0, 0.2, 1) !default;
$transition-ease-out: cubic-bezier(0.0, 0.0, 0.2, 1) !default;
$transition-ease-in: cubic-bezier(0.4, 0.0, 1, 1) !default;

//latter Spacing variables
$letter-spacing-base: 3px !default;
$letter-spacing-lg: 6px !default;
$letter-spacing-xl: 8px !default;

//Sizes Variables
$size-10: 10px !default;
$size-20: 20px !default;
$size-30: 30px !default;
$size-40: 40px !default;
$size-50: 50px !default;
$size-60: 60px !default;
$size-70: 70px !default;
$size-80: 80px !default;
$size-90: 90px !default;
$size-100: 100px !default;
$size-120: 120px !default;
