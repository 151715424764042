

.search_wrapper {
    // width:1300px;
        // width:100%;
    // width: 60%;
    @media (max-width: 767px) {
        width:100%;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        width: 666px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 980px;
    }
    @media (min-width: 1200px) and (max-width: 1550px) {
        width: 980px;
    }
    @media (min-width: 1550px) and (max-width: 1900px) {
        width: 1300px;
    }
    @media (min-width: 1900px) and (max-width: 2160px){
        width: 1620px;
    }
    @media (min-width: 2160px) {
        width: 1920px;
    }
    padding: 0 30px;
    // margin: 15px auto;
    margin: 15px auto 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // margin: 15px 0 20px 5px;
    // margin: 15px 0 20px 5px;
    .search {
        margin-top: 15px;
        margin-left: 15px;
        /* Rectangle: */
        // background: #464653;
        border-radius: 22.5px;
        // width: 45%;
        // position: relative;
        // right: 0;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        // @media screen and (min-width: 1200px) {
        //     width: 50%;
        //     top: 40px;
        // }
        // @media screen and (max-width: 1200px) {
        //     width: 45%;
        //     top: 90px;
        // }

        // left: 45%;
        // width: 1300px;
        // width: 80%;
        width: 60%;

        & > .react-autosuggest__container {
            // @media (max-width: 767px) {
            //     width:100%;
            // }
            // @media (min-width: 768px) and (max-width: 991px) {
            //     width: 666px;
            // }
            // @media (min-width: 992px) and (max-width: 1199px) {
            //     width: 980px;
            // }
            // @media (min-width: 1200px) and (max-width: 1550px) {
            //     width: 980px;
            // }
            // @media (min-width: 1550px) and (max-width: 1900px) {
            //     width: 1300px;
            // }
            // @media (min-width: 1900px) and (max-width: 2160px){
            //     width: 1620px;
            // }
            // @media (min-width: 2160px) {
            //     width: 1920px;
            // }
        }

        & > .react-autosuggest__container > input {
            // @media screen and (min-width: 1200px) {
            //     width: 50%;
            //     top: 40px;
            // }
            // @media screen and (max-width: 1200px) {
            //     width: 45%;
            //     top: 90px;
            // }
            // margin: auto;
            // width: 80%;
            width: 100%;

        }
        /* Search product …: */
        & > input:placeholder {
            opacity: 0.3;
            font-family: Metropolis-Regular;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
        }
        & > input:active {
            background: #464653;
        }
        & > div.wax_input_label {
            color: #FFFFFF;
            width:45px;
        }

        &_icon {
            cursor: pointer;
            width:18px;
            padding: 0;
            img {
                width:18px;
            }
        }
    }
}

.react-autosuggest__container {
    width: 100%;
}

.react-autosuggest__suggestions-container {
    // @media (max-width: 767px) {
    //     width:100%;
    // }
    // @media (min-width: 768px) and (max-width: 991px) {
    //     width: 666px;
    // }
    // @media (min-width: 992px) and (max-width: 1199px) {
    //     width: 980px;
    // }
    // @media (min-width: 1200px) and (max-width: 1550px) {
    //     width: 980px;
    // }
    // @media (min-width: 1550px) and (max-width: 1900px) {
    //     width: 1300px;
    // }
    // @media (min-width: 1900px) and (max-width: 2160px){
    //     width: 1620px;
    // }
    // @media (min-width: 2160px) {
    //     width: 1920px;
    // }
}
.react-autosuggest__suggestions-container--open {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.search_item {

}


// .react-autosuggest__container {
//     position: relative;
//   }

  .react-autosuggest__input {
    // width: 240px;
    // height: 30px;
    // padding: 10px 20px;
    // font-family: Helvetica, sans-serif;
    // font-weight: 300;
    // font-size: 16px;
    // border: 1px solid #aaa;
    // border-radius: 4px;
    padding-left: 15px;
    text-align: left;
    // -webkit-user-select: none;  /* Chrome all / Safari all */
    // -moz-user-select: none;     /* Firefox all */
    // -ms-user-select: none;      /* IE 10+ */
    // user-select: none;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    // top: 61px;
    // top: 65px;

    @media screen and (max-width: 575px) {
        top: 115px;
        left: 0px;
        width:100%;
        max-width: 500px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
        top: 115px;
        left: 100px;
        width: 500px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        left: 150px;
        width: 500px;
    }
    @media screen and (min-width: 992px){
        width: 500px;
    }
    // @media screen and (min-width: 992px) and (max-width: 1199px) {
    //     width: 500px;
    // }
    // @media screen and (min-width: 1200px) and (max-width: 1550px) {
    //     width: 500px;
    // }
    // @media screen and (min-width: 1550px) and (max-width: 1900px) {
    //     width: 500px;
    // }
    // @media screen and (min-width: 1900px) and (max-width: 2160px){
    //     width: 500px;
    // }
    // @media screen and (min-width: 2160px) {
    //     width: 500px;
    // }

    @media screen and (min-width: 1200px) {
        top: 65px;
        width: 500px; // TMP
    }
        // @media (max-width: 767px) {
    //     width:100%;
    // }
    // @media (min-width: 768px) and (max-width: 991px) {
    //     width: 666px;
    // }
    // @media (min-width: 992px) and (max-width: 1199px) {
    //     width: 980px;
    // }
    // @media (min-width: 1200px) and (max-width: 1550px) {
    //     width: 980px;
    // }
    // @media (min-width: 1550px) and (max-width: 1900px) {
    //     width: 1300px;
    // }
    // @media (min-width: 1900px) and (max-width: 2160px){
    //     width: 1620px;
    // }
    // @media (min-width: 2160px) {
    //     width: 1920px;
    // }

    // width: 280px;
    // width: 500px; // TMP
    // border: 1px solid #aaa;
    // background-color: #fff;
    background: #464653;
    color: $white;
    font-family: Helveti, sans-serif;
    font-weight: 500;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1450;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    /* width: 105px; */
    flex-wrap: wrap;

    // Activate scroll
    max-height: 1000px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    width: 100%; // TODO: for flex display check this !
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #3E3E4B;
    // background-color: #353540;
  }