

.topSubNav {
    @include dflex (row, space-between, flex-start);
    height: 60px;
    width: 100%;
    background: $sidebar-bg;
    border-radius: 28px;
    // margin: 30px auto; // todo: probably change when topbar will take space
    margin: 0px auto 30px auto;
    padding: 10px;
    font-family: Metropolis-Bold, sans-serif;
    font-weight: 700;

    &_links {
        // margin: 0px auto 30px auto;

        @media screen and (max-width: 576px) {
            padding: 10px 5px;
        }
        @media screen and (min-width: 577px) {
            padding: 10px;
        }
        font-family: Metropolis-Bold, sans-serif;
        font-weight: 700;

        a {
            font-style: normal;
        }
        span {
            color: $white;
            border-radius: 25px;
            // margin: 10px 4px 10px 10px;
            margin-right: 5px;
            cursor: pointer;

            font-family: Metropolis-Bold, sans-serif;
            font-weight: 700;
            @media screen and (max-width: 576px) {
                font-size: 11px;
                padding: 10px 2px;
            }
            @media screen and (min-width: 577px) {
                font-size: 14px;
                padding: 10px 20px;
            }
            // font-size: 14px;
            letter-spacing: 0;
            // line-height: 40px;

            // @media (max-width: 576px) {
            //     padding: 10px 12px;
            // }
            // @media (min-width: 577px) {
            //     padding: 10px 20px;
            // }

            &.active {
                // background: $author-color;
                background: $primary-btn;
            }
        }
    }
    &_filters {
        padding: 10px;
        cursor: pointer;
        & > span {
            padding-right: 5px;
            color: #fff;
            font-family: Metropolis-Bold, sans-serif;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0;
        }
        & > img {
            @media screen and (max-width: 991px) {
                width: 15px;
                height: 15px;
            }
            @media screen and (min-width: 992px){
                width: 20px;
                height: 20px;
            }
        }
    }
}