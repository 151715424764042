

.item_search {
    @include dflex(row, flex-start, flex-start);
    &_background {
        background-image: url(../../assets/images/item/item_bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        z-index: 4;
        width: 130px;
        height: 130px;
        border: none;
        cursor: pointer;
        @include border-radius(20px 0 0 20px);

        &_image {
            width: 130px;
            height: 130px;
            z-index: 5;
            border: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            cursor: pointer;
            @include border-radius(20px 0 0 20px);
        }
    }

    &_info {
        @include border-radius(0 20px 20px 0);
        min-height: 130px;
        width: 330px;
        padding: 10px 0px 0px 15px;
        // padding: 15px 0px 0px 20px;
        z-index: 5;
        background: $bg-dark;

        h3 {
            font-family: Metropolis-Bold, sans-serif;
            // font-size: 19px;
            font-weight: 700;
            color: #FFFFFF;
            letter-spacing: 0;
            // line-height: 38px;
            // line-height: 32px;
            margin: 0;

            font-size: 12px;
            line-height: 28px;
        }
    }
    h4 {
        /* Author: johnycage: */
        font-family: Metropolis-Regular, sans-serif;
        font-weight: 500;
        font-size: 13px;
        color: rgba(255,255,255,0.30);
        letter-spacing: 0;
        line-height: 18px; // TODO: look if not too samall
        margin: 0;
        span {
            color: $white;
            // opacity: 0.3; // GOOGLE DOESNT LIKE IT. FUCK.
            opacity: 0.5;
        }
        a {
            color: $author-color;
            &:hover {
                color: $author-color;
            }
        }
    }

    div.price {
        font-family: Metropolis-Regular, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        // line-height: 38px; // Too large for mobile
        line-height: 21px;
        margin: 0;
        @include dflex(row, flex-start, baseline);
        span {
            color: $wax-color;
        }
    }

    &_actions {
        height: 61px;
        // width: 290px;
        height: 217px;
        @include display-flex(flex, row, nowrap);
        @include justify-content(space-between);

    }
}

// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575px) { ... }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }


